.footer1-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer1-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer1-newsletter {
  gap: 24px;
  width: 500px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-content1 {
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.footer1-actions {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
}
.footer1-container {
  width: 365px;
  display: flex;
  align-items: flex-start;
}
.footer1-text-input {
  gap: 8px;
  width: 100%;
  height: 32px;
  display: flex;
  font-size: 16px;
  box-sizing: content-box;
  text-align: left;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  background-color: transparent;
}
.footer1-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer1-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-footer-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-column2 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-footer-links2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link14 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-link15 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-link16 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-credits {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.footer1-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
}
.footer1-footer-links3 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
.footer1-text10 {
  display: inline-block;
}
.footer1-text11 {
  display: inline-block;
}
.footer1-text12 {
  display: inline-block;
}
.footer1-text13 {
  display: inline-block;
}
.footer1-text14 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  height: auto;
  display: inline-block;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Nunito";
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.footer1-text15 {
  display: inline-block;
}
.footer1-text16 {
  display: inline-block;
}
.footer1-text17 {
  display: inline-block;
}
.footer1-text18 {
  display: inline-block;
}
.footer1-text19 {
  display: inline-block;
}
.footer1-text20 {
  display: inline-block;
}
.footer1-text21 {
  display: inline-block;
}
.footer1-text22 {
  display: inline-block;
}
.footer1-text23 {
  display: inline-block;
}
.footer1-text24 {
  display: inline-block;
}
.footer1-text25 {
  display: inline-block;
}
.footer1-text26 {
  display: inline-block;
}
.footer1-text27 {
  display: inline-block;
}
.footer1-text28 {
  display: inline-block;
}
@media(max-width: 991px) {
  .footer1-newsletter {
    width: 300px;
  }
  .footer1-form {
    width: 100%;
    flex-direction: column;
  }
  .footer1-container {
    width: 100%;
  }
  .footer1-text-input {
    width: 100%;
    padding: var(--dl-space-space-halfunit);
  }
  .footer1-button {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .footer1-content {
    flex-direction: column;
  }
  .footer1-newsletter {
    width: 100%;
  }
  .footer1-form {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .footer1-container {
    width: 100%;
  }
  .footer1-button {
    width: 208px;
  }
  .footer1-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer1-column1 {
    align-items: center;
  }
  .footer1-footer-links1 {
    align-self: center;
  }
  .footer1-column2 {
    align-items: center;
  }
  .footer1-footer-links2 {
    align-self: center;
  }
  .footer1-column3 {
    align-items: center;
  }
  .footer1-social-links {
    align-self: center;
  }
  .footer1-row {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer1-text10 {
    text-align: center;
  }
  .footer1-text13 {
    text-align: center;
  }
  .footer1-text27 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .footer1-actions {
    width: 100%;
  }
  .footer1-form {
    width: 100%;
    flex-direction: column;
  }
  .footer1-container {
    width: 100%;
  }
  .footer1-button {
    width: 100%;
  }
  .footer1-links {
    flex-direction: column;
  }
  .footer1-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links1 {
    align-items: center;
    justify-content: center;
  }
  .footer1-column2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links2 {
    align-items: center;
    justify-content: center;
  }
  .footer1-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer1-credits {
    gap: var(--dl-space-space-unit);
  }
  .footer1-row {
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links3 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
