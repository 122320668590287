.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text100 {
  color: #ca4646;
  display: inline-block;
  text-decoration: none;
}
.home-text101 {
  display: inline-block;
  text-decoration: none;
}
.home-text102 {
  display: inline-block;
}
.home-text103 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text104 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text105 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text106 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.home-text107 {
  display: inline-block;
  text-decoration: none;
}
.home-text108 {
  display: inline-block;
  text-decoration: none;
}
.home-text109 {
  display: inline-block;
  text-decoration: none;
}
.home-text110 {
  display: inline-block;
  text-decoration: none;
}
.home-text111 {
  display: inline-block;
  text-decoration: none;
}
.home-text112 {
  display: inline-block;
  text-decoration: none;
}
.home-text113 {
  display: inline-block;
  text-decoration: none;
}
.home-text114 {
  display: inline-block;
  text-decoration: none;
}
.home-text115 {
  display: inline-block;
  text-decoration: none;
  background-color: #962c2c;
}
.home-text116 {
  color: #8c3d3d;
}
.home-text118 {
  display: inline-block;
  text-decoration: none;
}
.home-text119 {
  display: inline-block;
  text-decoration: none;
}
.home-text120 {
  display: inline-block;
  text-decoration: none;
}
.home-text121 {
  display: inline-block;
}
.home-text122 {
  display: inline-block;
}
.home-text123 {
  display: inline-block;
}
.home-text124 {
  display: inline-block;
}
.home-text125 {
  display: inline-block;
  text-align: center;
  font-family: "Nunito";
}
.home-text126 {
  display: inline-block;
  text-align: center;
  font-family: "Nunito";
}
.home-text127 {
  display: inline-block;
  font-size: calc(38px + .0025*(100vw - 320px));
  font-family: "Nunito";
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text128 {
  color: #000000;
  display: inline-block;
}
.home-text129 {
  color: #000000;
  display: inline-block;
}
.home-text130 {
  color: #000000;
  display: inline-block;
}
.home-text131 {
  display: inline-block;
  font-size: calc(38px + .0025*(100vw - 320px));
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text132 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text133 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text134 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text135 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text136 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text137,
.home-text138,
.home-text139,
.home-text140,
.home-text141 {
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text138 {
  font-weight: 600;
}
.home-text139 {
  font-weight: 600;
}
.home-text140 {
  font-weight: 600;
}
.home-text141 {
  font-weight: 600;
}
.home-text142 {
  color: rgb(22, 22, 22);
  display: inline-block;
  font-family: "Nunito";
}
.home-text143 {
  display: inline-block;
  font-family: "Nunito";
}
.home-text144 {
  display: inline-block;
  font-family: "Nunito";
}
.home-text145 {
  display: inline-block;
  font-family: "Nunito";
}
.home-text146 {
  color: #1A1A1A;
  display: inline-block;
  font-size: 12px;
  font-family: "Nunito";
}
.home-text147 {
  color: #1A1A1A;
  display: inline-block;
  font-size: 12px;
  font-family: "Nunito";
}
.home-text150 {
  color: #1A1A1A;
  display: inline-block;
  font-size: 12px;
  font-family: "Nunito";
}
.home-text151 {
  fill: #51565f;
  color: #1A1A1A;
  display: inline-block;
  font-size: 12px;
  font-family: "Nunito";
}
.home-text152 {
  display: inline-block;
  font-size: calc(38px + .0025*(100vw - 320px));
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text153 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text154 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text155 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text156 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text157 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text158 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text159,
.home-text160,
.home-text161,
.home-text162,
.home-text163,
.home-text164 {
  text-align: left; /* Change from center to left */
}
.home-text159 {
  fill: var(--dl-color-theme-secondary2);
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text160 {
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text161 {
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text162 {
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text163 {
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text164 {
  color: #777777;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text165 {
  display: inline-block;
  font-size: 20px;
  font-family: "Nunito";
}
.home-text166 {
  display: inline-block;
  font-size: calc(38px + .0025*(100vw - 320px));
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text167 {
  color: var(--dl-color-theme-neutral-light);
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
}
.home-text168 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  height: 110px;
  display: inline-block;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 600;
}
.home-text173 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  display: inline-block;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 600;
}
.home-text177 {
  fill: var(--dl-color-theme-neutral-light);
  color: #d8cdcd;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
  margin-bottom: 0px;
}
.home-text182 {
  fill: #D8CDCD;
  color: #D8CDCD;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text184 {
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text187 {
  fill: #D8CDCD;
  color: #D8CDCD;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
}
.home-text191 {
  fill: #777777;
  color: #777777;
  display: inline-block;
}
.home-text192 {
  display: inline-block;
  font-size: 48px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.home-text193 {
  display: inline-block;
}
.home-text194 {
  display: inline-block;
}
.home-text195 {
  display: inline-block;
}
.home-text196 {
  display: inline-block;
}
.home-text197 {
  display: inline-block;
}
.home-text198 {
  display: inline-block;
}
.home-text199 {
  display: inline-block;
}
.home-text200 {
  display: inline-block;
}
.home-text201 {
  display: inline-block;
}
.home-text202 {
  display: inline-block;
}
.home-text203 {
  display: inline-block;
}
.home-text204 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  height: auto;
  display: inline-block;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Nunito";
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-text205 {
  display: inline-block;
}
.home-text206 {
  display: inline-block;
}
.home-text207 {
  display: inline-block;
}
.home-text208 {
  display: inline-block;
}
.home-text209 {
  display: inline-block;
}
.home-text210 {
  display: inline-block;
}
.home-text211 {
  display: inline-block;
}
@media(max-width: 991px) {
  .home-text127 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .home-text131 {
    text-align: center;
  }
  .home-text139 {
    text-align: center;
  }
  .home-text155 {
    text-align: center;
  }
  .home-text156 {
    text-align: center;
  }
  .home-text157 {
    text-align: center;
  }
  .home-text158 {
    text-align: center;
  }
  .home-text159,
  .home-text160,
  .home-text161,
  .home-text162,
  .home-text163,
  .home-text164 {
    text-align: left; /* Ensure left alignment */
  }
  .home-text209 {
    text-align: center;
  }
  .home-text210 {
    text-align: center;
  }
  .home-text211 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .home-text155 {
    text-align: center;
  }
  .home-text156 {
    text-align: center;
  }
  .home-text157 {
    text-align: center;
  }
  .home-text158 {
    text-align: center;
  }
  .home-text159,
  .home-text160,
  .home-text161,
  .home-text162,
  .home-text163,
  .home-text164 {
    text-align: left; /* Ensure left alignment */
  }
}
