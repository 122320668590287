.features16-layout300 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features16-section-title {
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-content1 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.features16-content2 {
  gap: 48px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.features16-row1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.features16-feature11 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-content3 {
  gap: 10px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.features16-image1 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features16-feature21 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-content4 {
  gap: 10px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.features16-image2 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features16-feature3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-content5 {
  gap: 10px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.features16-image3 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features16-content6 {
  gap: 48px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.features16-row2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.features16-feature12 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-content7 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.features16-image4 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features16-feature22 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.features16-content8 {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.features16-image5 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features16-text11 {
  display: inline-block;
  font-size: 35px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.features16-text12 {
  fill: #51565f;
  color: #51565f;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
}
.features16-text13 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
}
.features16-text14 {
  display: inline-block;
  font-size: 48px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.features16-text15 {
  fill: #51565f;
  color: #51565f;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
}
.features16-text16 {
  display: inline-block;
  font-size: 35px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.features16-text17 {
  display: inline-block;
  font-size: 35px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.features16-text18 {
  fill: #51565f;
  color: #51565f;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
}
.features16-text19 {
  display: inline-block;
  font-size: 35px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 700;
}
.features16-text20 {
  display: inline-block;
  font-size: 35px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.features16-text21 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
}
@media(max-width: 991px) {
  .features16-section-title {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .features16-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .features16-section-title {
    width: auto;
  }
  .features16-row1 {
    flex-direction: column;
  }
  .features16-feature21 {
    width: auto;
  }
  .features16-content4 {
    width: auto;
  }
  .features16-feature3 {
    width: auto;
  }
  .features16-row2 {
    flex-direction: column;
  }
  .features16-feature22 {
    width: auto;
  }
  .features16-text13 {
    text-align: center;
  }
  .features16-text14 {
    text-align: center;
  }
}
