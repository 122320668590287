.features24-container1 {
  padding-top: 0px;
}
.features24-tabs-menu {
  gap: var(--dl-space-space-twounits);
  width: 573px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #63c14b;
}
.features24-tab-horizontal1,
.features24-tab-horizontal2,
.features24-tab-horizontal3 {
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding: var(--dl-space-space-unit) 0; /* Add padding for better touch targets */
}
.features24-divider-container1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.features24-container2 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}
.features24-content1,
.features24-content2,
.features24-content3 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.features24-text3,
.features24-text4,
.features24-text5 {
  transition: font-size 0.3s ease; /* Smooth transition for font size changes */
}
.features24-divider-container2 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.features24-container3 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}
.features24-content2 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.features24-tab-horizontal3 {
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.features24-divider-container3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.features24-container4 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}
.features24-content3 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.features24-text2 {
  display: inline-block;
  font-size: 56px;
  font-family: "Nunito";
  margin-bottom: var(--dl-space-space-twounits);
}
.features24-text3 {
  color: #000000;
  display: inline-block;
}
.features24-text4 {
  color: #000000;
  display: inline-block;
}
.features24-text5 {
  color: #000000;
  display: inline-block;
}
@media(max-width: 991px) {
  .features24-tabs-menu {
    gap: var(--dl-space-space-unit);
  }

  .features24-text3,
  .features24-text4,
  .features24-text5 {
    font-size: 28px; /* Slightly smaller font size for medium screens */
  }
}
@media(max-width: 767px) {
  .features24-tabs-menu {
    width: 100%;
    gap: var(--dl-space-space-halfunit);
  }

  .features24-text3,
  .features24-text4,
  .features24-text5 {
    font-size: 24px; /* Even smaller font size for small screens */
  }

  .features24-tab-horizontal1,
  .features24-tab-horizontal2,
  .features24-tab-horizontal3 {
    padding: var(--dl-space-space-halfunit) 0; /* Reduce padding for smaller screens */
  }
}
@media(max-width: 479px) {
  .features24-tabs-menu {
    gap: var(--dl-space-space-quarterunit);
  }

  .features24-text3,
  .features24-text4,
  .features24-text5 {
    font-size: 20px; /* Smallest font size for very small screens */
  }

  .features24-tab-horizontal1,
  .features24-tab-horizontal2,
  .features24-tab-horizontal3 {
    padding: var(--dl-space-space-quarterunit) 0; /* Further reduce padding for very small screens */
  }
}
