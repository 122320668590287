.features23-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.features23-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start; /* Change from center to flex-start */
  flex-direction: column;
}

.features23-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: flex-start; /* Change from center to flex-start */
  flex-direction: column;
}

.thq-grid-3 {
  gap: var(--dl-space-space-twounits);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%; /* Add this to ensure full width */
}

.features23-container12,
.features23-container14,
.features23-container16,
.features23-container18,
.features23-container20,
.features23-container22 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
  height: 100%;  /* Ensure equal height */
}

.features23-container13,
.features23-container15,
.features23-container17,
.features23-container19,
.features23-container21,
.features23-container23 {
  width: 60px;
  height: 60px;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
  margin-bottom: var(--dl-space-space-unit);
}

.features23-image1,
.features23-image2,
.features23-image3,
.features23-image4,
.features23-image5,
.features23-image6 {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.features23-text27,
.features23-text25,
.features23-text33,
.features23-text31,
.features23-text30,
.features23-text32 {
  margin-bottom: var(--dl-space-space-unit);
  font-size: 24px;
  font-weight: 600;
}

.features23-text34,
.features23-text26,
.features23-text35,
.features23-text29,
.features23-text28,
.features23-text24 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 16px;
  line-height: 1.5;
}

@media(max-width: 991px) {
  .thq-grid-3 {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--dl-space-space-oneandhalfunits); /* Reduce gap */
  }
}

@media(max-width: 767px) {
  .features23-max-width {
    gap: var(--dl-space-space-unit); /* Reduce gap */
  }

  .thq-grid-3 {
    gap: var(--dl-space-space-unit); /* Reduce gap further */
  }

  .features23-container12,
  .features23-container14,
  .features23-container16,
  .features23-container18,
  .features23-container20,
  .features23-container22 {
    padding: var(--dl-space-space-oneandhalfunits);
  }

  .features23-container13,
  .features23-container15,
  .features23-container17,
  .features23-container19,
  .features23-container21,
  .features23-container23 {
    width: 50px;
    height: 50px;
    margin-bottom: var(--dl-space-space-halfunit);
  }

  .features23-image1,
  .features23-image2,
  .features23-image3,
  .features23-image4,
  .features23-image5,
  .features23-image6 {
    width: 28px;
    height: 28px;
  }

  .features23-text27,
  .features23-text25,
  .features23-text33,
  .features23-text31,
  .features23-text30,
  .features23-text32 {
    font-size: 20px;
    margin-bottom: var(--dl-space-space-halfunit);
  }

  .features23-text34,
  .features23-text26,
  .features23-text35,
  .features23-text29,
  .features23-text28,
  .features23-text24 {
    font-size: 14px;
  }
}

@media(max-width: 479px) {
  .features23-max-width {
    gap: var(--dl-space-space-halfunit); /* Reduce gap even further */
  }

  .thq-grid-3 {
    grid-template-columns: 1fr;
    gap: var(--dl-space-space-halfunit); /* Reduce gap even further */
  }

  .features23-container12,
  .features23-container14,
  .features23-container16,
  .features23-container18,
  .features23-container20,
  .features23-container22 {
    padding: var(--dl-space-space-unit);
  }

  .features23-container13,
  .features23-container15,
  .features23-container17,
  .features23-container19,
  .features23-container21,
  .features23-container23 {
    width: 40px;
    height: 40px;
  }

  .features23-image1,
  .features23-image2,
  .features23-image3,
  .features23-image4,
  .features23-image5,
  .features23-image6 {
    width: 24px;
    height: 24px;
  }

  .features23-text27,
  .features23-text25,
  .features23-text33,
  .features23-text31,
  .features23-text30,
  .features23-text32 {
    font-size: 18px;
  }

  .features23-text34,
  .features23-text26,
  .features23-text35,
  .features23-text29,
  .features23-text28,
  .features23-text24 {
    font-size: 14px;
  }
}
