.features231-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.features231-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features231-container1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.features231-container3 {
  flex: 1;
  height: 699px;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  margin-top: -0px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features231-image1 {
  width: var(--dl-size-size-medium);
  margin-top: 050px;
  object-fit: cover;
}
.features231-container4 {
  flex: 1;
  height: 699px;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features231-image2 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features231-container5 {
  flex: 1;
  height: 699px;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features231-image3 {
  width: var(--dl-size-size-medium);
  object-fit: cover;
}
.features231-text18 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  height: 110px;
  display: inline-block;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 600;
}
.features231-text23 {
  display: inline-block;
  font-size: 48px;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 700;
}
.features231-text24 {
  fill: #D8CDCD;
  color: #D8CDCD;
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
}
.features231-text26 {
  font-style: normal;
  font-family: "Nunito";
  font-weight: 500;
}
.features231-text29 {
  color: var(--dl-color-theme-neutral-light);
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 600;
}
.features231-text30 {
  fill: #D8CDCD;
  color: #D8CDCD;
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
}
.features231-text34 {
  fill: var(--dl-color-theme-neutral-light);
  color: #d8cdcd;
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
  margin-bottom: 0px;
}
.features231-text39 {
  display: inline-block;
  font-size: 18px;
  font-family: "Nunito";
}
.features231-text40 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  display: inline-block;
  font-style: normal;
  font-family: "Nunito";
  font-weight: 600;
}
.features231-text {
  color: #ffffff;
  text-align: center;
  width: 100%;
  display: inline-block;
  height: 56px;
  margin-bottom: 20px;
  /* Remove the following lines:
  height: 56px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  */
}

.features231-description {
  color: #D8CDCD;
  font-size: 14px;
  text-align: center;
  width: 80%;
  min-height: 130px; /* Set height to 130px */
  margin: auto auto 60px;;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.features231-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.features231-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the start (left) of the container */
  min-height: 450px;
  padding: 60px 50px;
  background-color: var(--dl-color-theme-primary2);
  box-shadow: 2px 2px 4px 0px #d4d4d4;
}

/* Remove the following class entirely:
.features231-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
*/

/* Add any additional responsive styles as needed */
.features231-layout349 .thq-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--dl-space-space-unit);
}

@media(max-width: 991px) {
  .features231-layout349 .features231-max-width {
    flex-direction: column;
  }
  
  .features231-layout349 .features231-container1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  
  .features231-layout349 .thq-grid-3 {
    grid-template-columns: 1fr;
  }
  
  .features231-layout349 .features231-container {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }
}

@media(max-width: 767px) {
  .features231-container1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .features231-container3 {
    width: 100%;
  }
  .features231-container4 {
    width: 100%;
  }
  .features231-container5 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .features231-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
